<template>
  <v-app>
    <v-banner
        class="lavBanner" 
        single-line
        height="400"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/AIHome-banner.png"
          height="100%"
          class="d-flex align-center"
        >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center" style="color: #fff">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  打造突破想象的沉浸式智能AI新业态
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px;margin:0 auto;text-align: center;display: block;">
                  推动物理世界的全面数字化转型
                </v-card-title>
                <!-- <div class="d-flex justify-center mt-5">
                  <v-btn
                    width="160"
                    class="font-size-20 rounded-pill"
                    color="#0084ff"
                    style="color: #fff"
                  >
                    立即体验
                  </v-btn>
                </div> -->
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 产品功能介绍 -->
    <!-- <v-container class="d-flex justify-center"> -->
      <v-card class=" warp2" :flat="true" style="width:1200px;margin:0 auto;d-flex justify-center">
        <div class="public-title" style="margin:68px 0 20px 0">
          <p>SERVICE INTRODUCTION</p>
          <div>
            <span>产品功能介绍</span>
            <span>利用多维感知、全局洞察、实时决策解决复杂局面下企业难题</span>
          </div>
        </div>
        <v-tabs v-model="tab" centered  class="g-tabs mt-8">
          <v-tab v-for="(item,index) in data.items" :key="index">
            <v-card-title class="font-size-20 " :style="{'color':tab == index ? '#0084ff!important':''}">{{ item.tab }}</v-card-title>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item,index) in data.items" :key="index" :transition="false">
              <div class="box">
                <transition name="slide-fade" appear>
                  <div class="right px-5" v-show="tab === index">
                    <v-card-title class="title px-0 py-1" v-text="item.title" />
                    <v-card-text class="tip" v-text="item.content" />
                  </div>
                </transition>
                <transition name="slide-fade-reverse" appear>
                  <v-img v-show="tab === index" :src="item.src" width="400" height="260" class="rounded-xl">
                    <template v-slot:placeholder>
                          <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                          >
                            <v-progress-circular
                                indeterminate
                                size="80"
                                width="8"
                                color="grey lighten-3"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                  </v-img>
                </transition>
              </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    <!-- </v-container> -->
    <!-- 优势 -->
    <div class="product-advantages" style="width:1200px;margin:0 auto;">
      <v-container class="max-width-1200" style="margin-top: 70px;">
        <v-row class="public-title justify-center pa-0 mt-0">
          <p>PRODUCT ADVANTAGES</p>
          <div>
            <span>产品优势</span>
          </div>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="pa-0 d-flex justify-center" v-for="(info,index) in advantageList" :key="index">
            <v-card flat class="" width="255">
              <v-img class="margin-l-center" width="120" height="120" contain :src="info.src"></v-img>
              <v-card-title class="justify-center mt-5 pa-0 font-size-24">{{ info.title }}</v-card-title>
              <v-card-text class="pa-0 mt-5 font-size-14 text-align-center">{{ info.content }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- 应用场景 -->
    <v-card class="warps warp4 mt-2" :flat="true">
      <div class="public-title">
        <p>BOUTIQUE TEMPLATE</p>
        <div>
          <span>应用场景</span>
          <span>全行业覆盖，打造刷爆朋友圈的趣味互动运营活动</span>
        </div>
      </div>
      <div class="con">
        <v-row>
          <v-col
            v-for="n in data.template"
            :key="n.length"
            class="d-flex child-flex"
            cols="3"
          >
            <div class="box-img" :style="{backgroundImage: 'url('+n.img+')'}">
              <div>
                {{ n.title }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import FanDate from '../../static/FanData.js'
import qs from 'qs'
export default {
  name: 'AIHome',
  components: {
  },
  data() {
    return {
      data:FanDate.AI,
      tab: null,
      advantageList: FanDate.AI.advantageList,
      template:FanDate.AI.template
    }
  },
  methods: {
    
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
.warp2 {
  .box {
    width: 1000px;
    margin: 30px auto 0;
    display: flex;
    align-items: center;
  }
  .left {
    margin-right: 80px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
    }
  }
  .right{
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    margin-right: 110px;
    .v-card__text {
      padding: 0;
      line-height: 30px!important;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 16px !important;
      color:#666!important;
      opacity:1;
    }
    .text {
      font-size: 14px;
      line-height: 23px;
    }
  }
}
.product-advantages{
  // height: 520px;
  display: flex;
  justify-content: center;
}
.warp4 {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 60px;
  .con {
    margin-top: 40px;
    .box-img {
      width: 240px;
      height: 160px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      border-radius: 10px;
      div {
        width: 100%;
        height: 100%;
        background-color:rgba(25, 26, 27, 0.5);
        color: #fff;
        font-size: 24px;
        display: flex;
        align-items: flex-end;
        padding: 11px;
        // justify-content: center;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
</style>
